// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","container":"container_fd64422","timestamp":"timestamp_bedda5e","timestampMine":"timestampMine_b7b1a22","timestampOther":"timestampOther_b7c71ed","messageBubbleWrapper":"messageBubbleWrapper_eaacee2","message":"message_bdb4671","myMessage":"myMessage_c8507eb","broadcastMessage":"broadcastMessage_f7d90a6","broadcastIcon":"broadcastIcon_a0a7302"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"container\":\"container_fd64422\",\"timestamp\":\"timestamp_bedda5e\",\"timestampMine\":\"timestampMine_b7b1a22\",\"timestampOther\":\"timestampOther_b7c71ed\",\"messageBubbleWrapper\":\"messageBubbleWrapper_eaacee2\",\"message\":\"message_bdb4671\",\"myMessage\":\"myMessage_c8507eb\",\"broadcastMessage\":\"broadcastMessage_f7d90a6\",\"broadcastIcon\":\"broadcastIcon_a0a7302\"}";
        // 1737747112092
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  